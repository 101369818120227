import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Layout, Container, Row, Col } from 'layout';
import Banner from 'components/Banner';
import ControledAccordion from 'components/ControledAccordion';
import { IFaqPageComponentProps } from './model';
import './FaqPageMain.scss';
import './FaqPageRTL.scss';

const FaqPage: FC<IFaqPageComponentProps> = ({
  data: {
    umbracoHome: { disclaimerDesktopImage, disclaimerMobileImage },
    umbracoFaq: {
      banner,
      accordionItems,
      seoMetaDescription,
      seoMetaKeywords,
      seoMetaTitle,
      seoCanonicalUrl,
      seoExternalHreflangs,
      url,
      defaultCompositions,
    },
  },
}) => (
  <Layout
    {...{ defaultCompositions, disclaimerDesktopImage, disclaimerMobileImage }}
    headerTransparent
    seo={{
      seoMetaDescription,
      seoMetaKeywords,
      seoMetaTitle,
      seoCanonicalUrl,
      seoExternalHreflangs,
    }}
  >
    <div className="faq-page">
      {banner?.[0] ? (
        <Banner
          {...banner[0].properties}
          url={url}
          image={banner[0].properties.localImage}
          key={banner[0].properties.title}
          title={banner[0].properties.title}
          className="faq-page__banner"
        />
      ) : null}
      <Container className="faq-page__container">
        <Row>
          <Col sm="12" md="12" lg="12" xl="12">
            <ControledAccordion className="faq-page__accordion" items={accordionItems} />
          </Col>
        </Row>
      </Container>
    </div>
  </Layout>
);

export const query = graphql`
  query FaqPage($url: String = "", $lang: String) {
    umbracoFaq(url: { eq: $url }, lang: { eq: $lang }) {
      url
      selectedIndex
      accordionItems {
        properties {
          answer
          question
        }
      }
      banner {
        properties {
          title
          variant
          localImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          imageAlt
          backgroundColour
        }
      }
      seoMetaTitle
      seoCanonicalUrl
      seoMetaDescription
      seoMetaKeywords
      seoExternalHreflangs {
        hreflang: key
        href: value
      }
      defaultCompositions {
        ...DefaultCompositionsFragment
      }
    }
    umbracoHome(lang: { eq: $lang }) {
      disclaimerMobileImage {
        ...FragmentImageWithAlt
      }
      disclaimerDesktopImage {
        ...FragmentImageWithAlt
      }
    }
  }
`;

export default FaqPage;
